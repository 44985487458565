.__multiselectrest {
  border-width: 0;
  border-style: solid;
  border-color: rgba(229, 231, 235, 1);
  border-bottom-width: 1px;
  overflow: hidden;
  --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--shadow);
  border-radius: 0.5rem;
  overflow-x: auto;
  --thead-background-color: rgba(249, 250, 251, 1);
  --tbody-background-color: #ffffff;
  --th-padding: 0.75rem 1.5rem;
  --th-text-align: left;
  --th-weight: 500;
  --th-color: rgba(107, 114, 128, 1);
  --th-font-size: 0.75rem;
  --tbody-tr-color: rgba(107, 114, 128, 1);
  --tbody-tr-font-size: 0.875rem;
  --tbody-tr-line-height: 1.25rem;
  --td-padding: 1rem 1.5rem;
}

.__multiselectrest > table {
  border-collapse: collapse;
  box-sizing: border-box;
  min-width: 100%;
}

.__multiselectrest > table > thead {
  background-color: var(--thead-background-color);
}

.__multiselectrest > table > tbody {
  background-color: var(--tbody-background-color);
  border-width: 0;
  border-style: solid;
}

.__multiselectrest > table > thead > tr > th {
  font-weight: var(--th-weight);
  font-size: var(--th-font-size);
  line-height: 1rem;
  padding: var(--th-padding);
  text-align: var(--th-text-align);
  color: var(--th-color);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.__multiselectrest > table > tbody >tr {
  border: 0 solid #e5e7eb;
  padding: 1rem 1.5rem;
  white-space: nowrap;
  color: var(--tbody-tr-color);
  font-size: var(--tbody-tr-font-size);
  line-height: var(--tbody-tr-line-height);
}

.__multiselectrest > table > tbody > tr > td {
  white-space: nowrap;
  padding: var(--td-padding);
  vertical-align: middle;
}

.__multiselectrest>table>tbody>tr>td>.__table_actions {
  display: flex;
  align-items: center;
  justify-content: right;
}

.__multiselectrest>table>tbody>tr>td>.__table_actions>* {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.__multiselectrest>table>tbody>tr>td>.__table_actions> :first-child {
  margin-left: 0rem;
}

.__multiselectrest>table>tbody>tr>td>.__table_actions> :last-child {
  margin-right: 0rem;
}

.divide-y> :not([hidden])~ :not([hidden]) {
  --mb-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--mb-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--mb-divide-y-reverse));
}

.divide-gray-200> :not([hidden])~ :not([hidden]) {
  --mb-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--mb-divide-opacity));
}

.__table_actions_column {
  width: 180px;
  text-decoration: underline;
  cursor: pointer;
}


.__multiselectrest > table > tbody > tr > td ~ .first-row{
  padding: 0px 8px 0px 8px;
}

.__circle-button {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  padding: 0px;
  color: white;
}

.__remove-button {
 
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  padding: 0px;

  color: var(--tbody-tr-color);
  background-color: white;

}

.__remove-button:hover {
  background-color: #cccccc;
}

.__textbox-input {
  --line-height: 1.25rem;
  --font-size: 0.74rem;

}