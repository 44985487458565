.__card-container {
  background-color: white;
  border-radius: 0.5rem/0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

 .__form {
   display: flex;
   flex-direction: column;
   gap: 10px;
   width: fit-content;
 }

 .__form > main {
    display: flex;
    flex-direction: column;
    gap: 12px;
 }

 .__form>footer {
   display: flex;
   justify-content: right;
 }