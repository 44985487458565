.__search-list {
  width: fit-content;
  min-width: 580px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.__search-list > header {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}


.__search-list > header > section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.__search-list > footer{
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  color: rgba(107, 114, 128, 1);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.__search-list>footer>span {
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
}

.navigation-buttons> {
  display: inline-flex;
  isolation: isolate;
  gap: 0px;
}

.navigation-buttons>button {
  background-color: white;
  color: rgba(107, 114, 128, 1);
  border-color: rgb(203 206 210);
  border-radius: 0;
  border-width: 1;
  margin-left: -1px;
}

.navigation-buttons>button:hover {
  color: white;
}

.navigation-buttons>button:first-child {
  margin-left: 0px;
  border-radius: 0.375rem 0 0 0.375rem;
}

.navigation-buttons>button:last-child {
  border-radius: 0 0.375rem 0.375rem 0;
}

.navigation-buttons>button:disabled {
  background-color: white;
  color: #c4c4c4;
  cursor: default;
}

.create-actions {
  color: rgba(79, 70, 229, 1);
  font-weight: 500;
  font-size: 0.875rem;
  text-decoration: underline;
  margin-right: 10px;
}