div.__select-class {
  max-width: 80vh;
  display: flex;
  
}
div.__select-class > * {
  flex: 1;
  margin-right:1rem;
}
.__button_cv_approval button{
   flex-grow: 1;
  margin: 0 5px; 
  margin-left: 30px;
   flex-grow: 1;
   margin-top:2rem;
}
.__button_cv_approval {
  margin-left: 5rem;
}
