.checkbox-label {
    display: inline-flex;
    align-items: center;
}

.checkbox-label input {
    display: none;
}

.checkbox-custom {
    width: 1em;
    height: 1em;
    border: 2px solid #ccc;
    border-radius: 0.25em;
    margin-right: 0.5em;
}

.checkbox-label input:checked+.checkbox-custom {
    background-color: #ccc;
}

.checkbox-label input:checked+.checkbox-custom:before {
    content: '✔';
    display: block;
    text-align: center;
    color: white;
    font-size: 0.8em;
}