.not-authorized {
  background-image: url('../../img/background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  display: grid;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
