.__page-title {
  background-color: var(--title-bkg-color);
  line-height: 6em;
  padding: 20px 0px 0px 20px;
  color: #5f5f7f;
}

.__page-title > ul {
  display: flex;
  gap: 6px;
  align-items: center;
  color: #8c8ca4;
}

.__page-title>ul > li {
  display: flex;
  align-items: center;
  line-height: 2.6rem;
}

.__page-title > h1{
  padding-bottom: 20px;
  line-height: 2.2rem;
}