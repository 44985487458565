.requirements {
  gap: 20px;
}

.__requirement_apply {
  width: auto;
}

.__requirement_apply > .__form > main {
  flex-direction: row;
  flex-wrap: wrap;
}

.__requirement_apply .__select {
  width: 320px;
}

.__afinidad {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
  width: 200px;
  padding: 20px;
  color: rgba(107, 114, 128, 1);
}

.__afinidad > span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 2.5rem;
}

.__requirement_apply .__cv-uploader-container {
  display: flex;
  max-width: 650px;
  flex-grow: 1;
}

.__requirement_apply .__fields {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.__card-container > main {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.__card-container > main > .form-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  row-gap: 2.5rem;
  column-gap: 2rem;
  border-bottom: 1px solid rgba(17, 24, 39, 0.1);
  padding-bottom: 80px;
}

.__card-container > main > .form-fields > header {
  width: 360px;
}

.__card-container > main > .form-fields > main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.__card-container > main > .form-fields > header > h2 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
  color: #6d7588;
}

.__card-container > main > .form-fields > header > p {
  color: #6b7280;
}

.simple-links {
  color: rgb(79 70 229 / 1);
}

.__requirement_apply .rejected {
  color: #e3342f;
}

.__requirement_apply .approved {
  color: #38c172;
}

.__requirement_apply .pending {
  color: #f7ac61;
}

#benefits ul {
  z-index: 9999;
}

.__multiselectrest {
  overflow: visible !important; 
}