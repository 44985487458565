.__logo-picker {
  --font-size: 0.875rem;
  --color: #6b7280;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: var(--font-size);
  color: var(--color);
  font-weight: 500;
}

.__logo-picker> header > label {
  display: flex;
  justify-content: space-between;
  line-height: var(--line-height);
  font-size: var(--font-size);
  font-weight: 500;
  margin-left: 2px;

}

.__logo-picker>header>label>span:first-child {
  color: rgba(107, 114, 128, 1);
}

.__logo-picker>header>label>span:last-child {
  color: rgba(107, 114, 128, 1);
}

.__logo-picker > main {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  
}

.__logo-picker>main>section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.__logo-picker>main>section>img {
  object-fit: cover;
  width: 96px;
  height: 96px;
}

.__logo-picker>main>section> section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.__logo-picker>main>section input[type=file] {
  display: none;
}

.__card-container .__company-card {
    background-color: aquamarine;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.__card-container .__company-card > img {
  width: 96px;
  height: 96px;
  border-radius: 8px;
}

.__card-container .__company-card> .labels {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 6px;
}

.__card-container .__company-card>.labels > header {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 800;
}

.__card-container .__company-card>.labels>main {
  font-size: x-large;
  line-height: 1.6rem;
  margin-top: 2px;
}

.__card-container .__company-card>.labels>footer {
  font-size: 16px;
  font-weight: 300;
  margin-top: 8px;
}
