.home-page {
  width: calc(100% + 40px);
  margin-left: -20px;
}

.home-page > .__card-container > header {
  display: flex;
}

.__card-container.simple-table {
  width: 400px;
}

.dashboard > main {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  flex-direction: column;
}

.dashboard > main > section {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.table-overflow {
  overflow-x: auto;
}

.stat-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 23px;
}
