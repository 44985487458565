.__referred {
  max-width: 650px;
  width: 100%;
}

.__referred .__cv-uploader-container {
  display: flex;
  max-width: 650px;
  flex-grow: 1;
}

.__referred .__text-field,
.__referred .__flex-layout .__text-field div {
  width: 100% !important;
}

.__referred .__form-title {
  background-color: var(--title-bkg-color);
  padding: 5px;
  color: #5f5f7f;
  font-size: 1.5em;
}