.__header {
  display: flex;
  align-items: center;
  background-color: var(--header-bkg-color);
  color: var(--header-color);
  font-size: 2rem;
  padding-left: 12px;
  gap: 20px;

  /* alternative do not delete: */
  /* background: linear-gradient(90deg, #433EAC 0%, #FC466B 100%); */
  background: linear-gradient(90deg, #433EAC 0%, #00ff88 100%);
  


}

.__header>label {  
  color: #fff;
  margin-top: -5px;
  cursor: pointer;
}

@media (min-width: 900px) {
  .__header>label {
    display: none;
  }
}

