.__kpi-card > .__card-container {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 300px;
}

.__kpi-card > .__card-container > main {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding: 22px;
  border-radius: 6px 6px 0 0;
}

.__kpi-card > .__card-container > main > span {
  width: 48px;
  height: 48px;
  background-color: #6366f1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.__kpi-card > .__card-container > main > div {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.__kpi-card > .__card-container > main > div > label {
  color: rgb(107, 114, 128);
  font-size: 14px;
  font-weight: 500;
}

.__kpi-card > .__card-container > main > div > h1 {
  color: #111827;
  font-size: 24px;
}

.__kpi-card > .__card-container > footer {
  color: #4f46e5;
  background-color: rgb(238, 239, 241);
  font-size: 14px;
  padding: 18px;
  padding-left: 22px;
  border-radius: 0 0 6px 6px;
  height: 52px;
}

.__kpi-card > .__card-container > footer > a {
  color: #4f46e5;
  font-size: 14px;
  text-decoration: none;
}
