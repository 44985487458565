.__button__xs {
  border-radius: 0.25rem;
  padding: 0.375rem 0.625rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.__button__sm {
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1rem;
}

.__button__md {
  border-radius: 0.375rem;
  padding: 8px 16px;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.__button__lg {
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.__button__xl {
  border-radius: 0.375rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.__button > span {
  display: flex;
}

.__button__xl > span > svg {
  height: 1.25rem;
  width: 1.25;
  overflow: hidden;
}

.__button.__button__xl > .icon__start {
  margin-left: -0.25rem;
  margin-right: 0.75rem;
}

.__button.__button__xl > svg:nth-of-type(2) {
  margin-left: 0.75rem;
  margin-right: -0.25rem;
}

.__button__lg > span > svg {
  height: 1.25rem;
  width: 1.25;
  overflow: hidden;
}

.__button.__button__lg > .icon__start {
  margin-left: -0.25rem;
  margin-right: 0.75rem;
}

.__button.__button__lg > .icon__end {
  margin-left: 0.75rem;
  margin-right: -0.25rem;
}

.__button__md > span > svg {
  height: 1.25rem;
  width: 1.25;
  overflow: hidden;
}

.__button.__button__md > .icon__start {
  margin-left: -0.25rem;
  margin-right: 0.75rem;
}

.__button.__button__md > .icon__end {
  margin-left: 0.75rem;
  margin-right: -0.25rem;
}

.__button__sm > span > svg {
  height: 1rem;
  width: 1.25;
  overflow: hidden;
}

.__button.__button__sm > .icon__start {
  margin-left: -0.125rem;
  margin-right: 0.5rem;
}

.__button.__button__sm > .icon__end {
  margin-left: 0.5rem;
  margin-right: -0.125rem;
}

.__button__xs > span > svg {
  height: 1rem;
  width: 1.25;
  overflow: hidden;
}

.__button.__button__xs > .icon__start {
  margin-left: -0.125rem;
  margin-right: 0.5rem;
}

.__button.__button__xs > .icon__end {
  margin-left: 0.5rem;
  margin-right: -0.125rem;
}

.__button {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  font-weight: 500;
  font-family: inherit;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  cursor: pointer;
  color: white;
  --mb-bg-opacity: 1;
  background-color: rgba(79, 70, 229, 1);
  --mb-ring-inset: var(--mb-empty, /*!*/ /*!*/);
  --mb-ring-offset-width: 0px;
  --mb-ring-offset-color: #fff;
  --mb-ring-color: rgba(59, 130, 246, 0.5);
  --mb-ring-offset-shadow: 0 0 #0000;
  --mb-ring-shadow: 0 0 #0000;

  --mb-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--mb-ring-offset-shadow, 0 0 #0000),
    var(--mb-ring-shadow, 0 0 #0000), var(--mb-shadow);
  --mb-text-opacity: 1;
  color: rgba(255, 255, 255, var(--mb-text-opacity));
  --mb-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--mb-space-y-reverse)));
  margin-bottom: calc(0px * var(--mb-space-y-reverse));
}

.__button:hover {
  --mb-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--mb-bg-opacity));
}

.__button:focus {
  --mb-ring-offset-width: 2px;
  --mb-ring-offset-shadow: var(--mb-ring-inset) 0 0 0
    var(--mb-ring-offset-width) var(--mb-ring-offset-color);
  --mb-ring-shadow: var(--mb-ring-inset) 0 0 0
    calc(2px + var(--mb-ring-offset-width)) var(--mb-ring-color);
  box-shadow: var(--mb-ring-offset-shadow), var(--mb-ring-shadow),
    var(--mb-shadow, 0 0 #0000);
  --mb-ring-opacity: 1;
  --mb-ring-color: rgba(99, 102, 241, var(--mb-ring-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.__button:disabled,
.__button[disabled] {
  background-color: #cccccc;
}
