.__table {
  border-width: 0;
  border-style: solid;
  border-color: rgba(229, 231, 235, 1);
  border-bottom-width: 1px;
  overflow: hidden;
  --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--shadow);
  border-radius: 0.5rem;
  overflow-x: auto;
  --thead-background-color: rgba(249, 250, 251, 1);
  --tbody-background-color: #ffffff;
  --th-padding: 0.75rem 1.5rem;
  --th-text-align: left;
  --th-weight: 500;
  --th-color: rgba(107, 114, 128, 1);
  --th-font-size: 0.75rem;
  --tbody-tr-color: rgba(107, 114, 128, 1);
  --tbody-tr-font-size: 0.875rem;
  --tbody-tr-line-height: 1.25rem;
  --td-padding: 1rem 1.5rem;
}

.__table > table {
  border-collapse: collapse;
  box-sizing: border-box;
  min-width: 100%;
}

.__table > table > thead {
  background-color: var(--thead-background-color);
}

.__table > table > tbody {
  background-color: var(--tbody-background-color);
  border-width: 0;
  border-style: solid;
}

.__table > table > thead > tr > th {
  font-weight: var(--th-weight);
  font-size: var(--th-font-size);
  line-height: 1rem;
  padding: var(--th-padding);
  text-align: var(--th-text-align);
  color: var(--th-color);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.__table > table > tbody > tr {
  border: 0 solid #e5e7eb;
  padding: 1rem 1.5rem;
  white-space: nowrap;
  color: var(--tbody-tr-color);
  font-size: var(--tbody-tr-font-size);
  line-height: var(--tbody-tr-line-height);
}

.__table > table > tbody > tr > td {
  white-space: nowrap;
  padding: var(--td-padding);
  vertical-align: middle;
}
.__table > table > tbody > tr > td > div.__table_fields:first-child {
  font-weight: 500;
}

.__table > table > tbody > tr > td > div.__table_fields:not(:first-child) {
  color: rgb(137, 143, 153);
}

.__table > table > tbody > tr > td > .__table_actions {
  display: flex;
  align-items: center;
  justify-content: right;
}

.__table > table > tbody > tr > td > .__table_actions > * {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.__table > table > tbody > tr > td > .__table_actions > :first-child {
  margin-left: 0rem;
}
.__table > table > tbody > tr > td > .__table_actions > :last-child {
  margin-right: 0rem;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --mb-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--mb-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--mb-divide-y-reverse));
}
.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --mb-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--mb-divide-opacity));
}

.__table_actions_column {
  width: 180px;
  text-decoration: underline;
  cursor: pointer;
}

/* Responsive Table */
/* @media screen and (max-width: 900px) {

    .__table th {

      display: none;

    }
    .__table td {
      
      display: grid;
      row-gap: 1rem;
      padding: 0.75rem 1rem;
      grid-template-columns: 8rem auto;
      
    }

    .__table td:first-child {
      padding-top: 2rem;
    }

    .__table td:last-child {
      padding-bottom: 2rem;
    }

  .__table td::before {
      content: attr(data-label) " ";
      font-weight: 700;
      text-transform: uppercase;
    }
} */
