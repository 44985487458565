.__select-box {
  --width: 100%;

  --invalid-color: #dc2626;

  --label-font-size: 0.875rem;
  --label-line-height: 1.5rem;
  --label-font-weight: 500;
  --label-color: #6b7280;
  --label-padding-x: 2px;
  --second-label-color: #6b7280;

  --input-text-color: #111827;
  --input-padding-y: 0.5rem;
  --input-padding-x: 0.75rem;
  --input-font-size: 0.875rem;
  --input-line-height: 1.25rem;
  --input-border-size: 1px;
  --input-border-color: #d1d5db;
  --input-border-radius: 0.375rem;
  --input-background-color: #fff;
  --input-focus-outline-color: #6366f1;
  --input-focus-outline-size: 2px;
  --input-placeholder-color: #9ca3af;
  --input-placeholder-selected-color: #111827;
  --input-icon-size: 1.25rem;
  --input-icon-color: #9ca3af;
  --input-disabled-bg-color: #f2f2f2;

  --list-border-color: #e5e7eb;
  --list-border-radius: 0.375rem;
  --list-bg-color: #fff;
  --list-max-height: 15rem;
  --list-padding-y: 0.25rem;

  --list-no-results-text-color: #9ca3af;

  --option-color: #111827;
  --option-focus-text-color: #fff;
  --option-focus-bg-color: #4f46e5;
  --option-selected-icon-color: var(--option-focus-bg-color);
  --option-selected-icon-size: var(--input-icon-size);
  --option-font-size: 0.875rem;
  --option-line-height: 1.25rem;
  --option-padding-y: 0.5rem;

  --message-top: 0.25rem;
  --message-font-size: 0.75rem;
  --message-line-height: 1rem;
  --message-color: #6b7280;
  --message-font-weight: 500;
  --message-padding-x: 2px;

  display: flex;
  flex-direction: column;
  word-wrap: break-word;
}

.__select-box > label {
  display: flex;
  justify-content: space-between;
  font-size: var(--label-font-size);
  line-height: var(--label-line-height);
  font-weight: var(--label-font-weight);
  padding-left: var(--label-padding-x);
  padding-right: var(--label-padding-x);
}

.__select-box > label > span:first-child {
  color: var(--label-color);
}

.__select-box > label > span:last-child {
  color: var(--second-label-color);
}

.__select-box > div {
  position: relative;
}

.__select-box > div > input {
  color: var(--input-text-color);
  width: var(--width);
  font-size: var(--input-font-size);
  line-height: var(--input-line-height);
  background-color: var(--input-background-color);
  border: solid var(--input-border-size) var(--input-border-color);
  border-radius: var(--input-border-radius);
  padding-left: var(--input-padding-x);
  padding-right: calc(1rem + var(--input-icon-size));
  padding-top: var(--input-padding-y);
  padding-bottom: var(--input-padding-y);
  text-align: left;
  cursor: default;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.__select-box > div > input:focus {
  outline: var(--input-focus-outline-size) solid transparent;
  outline-offset: var(--input-focus-outline-size);
  border-color: var(--input-focus-outline-color);
  box-shadow: 0 0 0 calc(var(--input-focus-outline-size) - 1px) var(--input-focus-outline-color);
}

.__select-box > div > input::placeholder {
  color: var(--input-placeholder-color);
}

.__select-box > div > input:disabled {
  background-color: var(--input-disabled-bg-color);
}

.__select-box > div > .icons {
  color: var(--input-icon-color);
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0;
  right: 0.5rem;
  height: 100%;
  pointer-events: none;
}

.__select-box > div > .icons > span {
  flex-shrink: 0;
  width: var(--input-icon-size);
  height: var(--input-icon-size);
}

.__select-box > div > .icons > span:first-child {
  pointer-events: all;
}

.__select-box > div > ul {
  z-index: 1;
  position: absolute;
  margin-top: calc(var(--input-focus-outline-size) - var(--input-border-size) + 2px);
  width: var(--width);
  border-radius: var(--list-border-radius);
  background-color: var(--list-bg-color);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  max-height: var(--list-max-height);
  padding-top: var(--list-padding-y);
  padding-bottom: var(--list-padding-y);
  border: solid 1px var(--list-border-color);
  overflow: auto;
}

.__select-box > div > ul > li {
  font-size: var(--option-font-size);
  line-height: var(--option-line-height);
  color: var(--option-color);
  cursor: default;
  position: relative;
  padding-top: var(--option-padding-y);
  padding-bottom: var(--option-padding-y);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  display: flex;
  align-items: center;
}

.__select-box > div > ul > li > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
}

.__select-box > div > ul > li > .icon {
  flex-shrink: 0;
  height: var(--input-icon-size);
  width: var(--input-icon-size);
  color: var(--option-selected-icon-color);
}

.__select-box > div > ul > li > .icon:last-child {
  padding-left: 0.5rem;
  width: calc(0.5rem + var(--input-icon-size));
  margin-left: auto;
}

.__select-box > div > ul > li.focus {
  color: var(--option-focus-text-color);
  background-color: var(--option-focus-bg-color);
}

.__select-box > div > ul > li.focus > .icon {
  color: var(--option-focus-text-color);
}

.__select-box > div > ul > li.no-results {
  color: var(--list-no-results-text-color);
  display: flex;
  justify-content: center;
}

.__select-box > div > ul > li.selected > span {
  font-weight: 600;
}

.__select-box > .messages {
  display: flex;
  flex-direction: column;
  position: relative;
  top: var(--message-top);
  font-size: var(--message-font-size);
  line-height: var(--message-line-height);
  color: var(--message-color);
  font-weight: var(--message-font-weight);
  padding-left: var(--message-padding-x);
  padding-right: var(--message-padding-x);
}

.__select-box > .messages > span:last-child {
  color: var(--invalid-color);
}

.__select-box.invalid > label > span:first-child {
  color: var(--invalid-color);
}

.__select-box.invalid > label > span:last-child {
  color: var(--invalid-color);
}

.__select-box.invalid > div > input {
  border: solid 1px var(--invalid-color);
}

.__select-box.no-search > div > input {
  color: transparent;
}

.__select-box.selected > div > input {
  padding-right: calc(1rem + var(--input-icon-size) * 2);
}

.__select-box.selected > div > input::placeholder {
  color: var(--input-placeholder-selected-color);
}
