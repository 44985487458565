.__sidebar {
  display: flex;
  flex-direction: column;
  background-color: var(--menu-bkg-color); /* #49b9d0; */
  color: #bbb8ff;
  row-gap: 10px;
  align-content: space-around;
  justify-content: space-between;
  line-height: 1.4rem;
  transition: transform 2s ease-in-out 1s;
  font-size: 0.875rem;
}

.__sidebar > .top-section > header {
  display: flex;
  align-items: center;
}

.__sidebar > .top-section > header > span {
  margin-top: 10px;
  margin-left: 8px;
  font-size: 0.8rem;
  background: -webkit-linear-gradient(45deg, #6ccaf6, #00ff95 80%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.__sidebar > .top-section > nav > ul > hr {
  margin-top: 23px;
  margin-bottom: 16px;
  border: 0;
  border-top: 1px solid #8581d32e;
}

.__sidebar > .top-section > nav > ul > hr:first-child {
  margin-top: 0px;
}

.__sidebar > .top-section > nav > ul > li > div,
.__sidebar > .top-section > nav > ul > li > .submenu > li > a,
.__sidebar > .top-section > nav > ul > li > a {
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 2.05rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: auto;
}

.__sidebar > .top-section > nav > ul > .profile {
  /* margin-right: 35px;
  display: block; */
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  align-items: center;
  padding-inline: 14px;
  font-weight: 600;
  color: white;
}

.__sidebar > .top-section > nav > ul > .profile > .__avatar > img {
  border-color: #a4a0f5;
  border-width: 2px;
}

.__sidebar > .top-section > nav > ul > .profile > span {
  display: flex;
  flex-wrap: wrap;
}

.__sidebar > .top-section > nav > ul > li > .submenu > li > a.active,
.__sidebar > .top-section > nav > ul > li > a.active {
  color: white;
}

.__sidebar > .top-section > nav > ul > li > .submenu > li > a:hover,
.__sidebar > .top-section > nav > ul > li > a:hover {
  color: white;
}

.__sidebar > .top-section > nav > ul > li > .submenu {
  margin-left: 12px;
  line-height: 0.875em;
  display: none;
}

.__sidebar > .top-section > nav > ul > li > label {
  padding-inline: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 32px;
}

.__sidebar > .top-section > nav > ul > li > input[type='checkbox'] {
  display: none;
}

.__sidebar > .top-section > nav > ul > li > input[type='checkbox']:checked ~ .submenu {
  display: block;
}

.__sidebar > .top-section > nav > ul > li > input[type='checkbox']:checked ~ label > svg {
  transform: rotate(90deg);
  transition: transform 0.2s ease-in-out;
}

.__sidebar > .top-section > nav > ul > li > input[type='checkbox']:not(:checked) ~ label > svg {
  transition: transform 0.2s ease-in-out;
}

@media (max-width: 900px) {
  .__sidebar {
    display: none;
  }
}

@media (max-width: 460px) {
  .__sidebar {
    display: none;
  }
}
