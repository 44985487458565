.__text-field {
  --radius: 0.375rem;
  --line-height: 1.25rem;
  --width: 100%;
  --font-size: 0.875rem;
  --border-color:  rgba(209, 213, 219, 1);
  --pre-background-color: rgba(249, 250, 251, 1);
  --pre-font-color: rgba(107, 114, 128, 1);
  --border-style: solid;
  --invalid-border-color: rgba(220, 38, 38, 1);
  display: flex;
  flex-direction: column;
  max-width: var(--width);
  
}

/* label section */
.__text-field > label  {
  display: flex;
  justify-content: space-between;
  line-height: var(--line-height);
  font-size: var(--font-size);
  font-weight: 500;
  margin-left: 2px;
  
}

.__text-field> label > span:first-child {
  color: rgba(107, 114, 128, 1);
}

.__text-field > label > span:last-child {
  color: rgba(107, 114, 128, 1);
}

/* input section */
.__text-field > div {
  display: flex;
  margin-top: 0.25rem;
  border-style: none;
}

.__text-field > div > * {
  line-height: var(--line-height);
  font-size: var(--font-size);
}

.__text-field .unique {
  width: var(--width);
  border-radius: var(--radius);
  border-color: var(--border-color);
  border-style: var(--border-style);
  border-width: 1px;  
  padding: 0.5rem 0.75rem;
}

.__text-field .pre-section {
  
  border-style: var(--border-style);
  border-radius: var(--radius) 0 0 var(--radius);
  border-width: 1px;
  border-right-width: 0px;
  border-color: var(--border-color);
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  background-color: var(--pre-background-color);
  color:var(--pre-font-color);

}

/* input styles */
.__text-field .first {
  
  border-radius: var(--radius) 0 0 var(--radius);
  border-color: var(--border-color);
  border-style: var(--border-style);
  border-width: 1px;
  
  padding: 0.5rem 0.75rem;
  width: 100%;
}

.__text-field .second {
  
  border-radius: 0rem var(--radius) var(--radius) 0rem;
  border-color: var(--border-color);
  border-style: var(--border-style);
  border-width: 1px;
  
  padding: 0.5rem 0.75rem;
  width: 100%;
}

.__text-field .middle {
  
  border-radius: 0;
  border-color: var(--border-color);
  border-style: var(--border-style);
  border-width: 1px;  
  padding: 0.5rem 0.75rem;
  width: 100%;
}

.__text-field .post-section {

  font-size: var(--font-size);
  border-radius: 0 var(--radius) var(--radius) 0;
  border-style: var(--border-style);
  border-width: 1px;
  border-left-width: 0px;
  border-color: var(--border-color);
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  background-color: var(--pre-background-color);
  color:var(--pre-font-color);

}

/* message or comments section */

.__text-field > .message {
  margin-top: 0.25rem;
  font-size: var(--font-size);
  line-height: 1.25rem;
  color: rgba(107, 114, 128, 1);
  font-weight: 500;
  margin-left: 2px;
}
.__text-field.invalid .unique {
  
  border-color: var(--invalid-border-color);
 
}

.__text-field.invalid .first {
  
  border-color: var(--invalid-border-color);

}

.__text-field.invalid .second {
  
  border-color: var(--invalid-border-color);
  
}

.__text-field.invalid > label > span:first-child {
  color: var(--invalid-border-color);
}

.__text-field.invalid > label > span:last-child {
  color: var(--invalid-border-color);
}

.__text-field > .validationMessage {
  margin-top: 0.25rem;
  font-size: var(--font-size);
  line-height: 1.25rem;
  color: var(--invalid-border-color);
  font-weight: 500;
  margin-left: 2px;
}

.__text-field.required .unique {
  
  border-color: var(--invalid-border-color);
 
}

.__text-field.required .first {
  
  border-color: var(--invalid-border-color);

}

.__text-field.required .second {
  
  border-color: var(--invalid-border-color);
  
}

.__text-field.required > label > span:first-child {
  color: var(--invalid-border-color);
}

.__text-field.required > label > span:last-child {
  color: var(--invalid-border-color);
}

.__text-field > .requiredMessage {
  margin-top: 0.25rem;
  font-size: var(--font-size);
  line-height: 1.25rem;
  color: var(--invalid-border-color);
  font-weight: 500;
  margin-left: 2px;
}


.__text-field.inner-icon > div {
  display: block;
  margin-top: 0.25rem;
  border-style: none;
  position: relative;
  border-radius: 0.375rem;
  text-size-adjust: 100%;
  line-height: 24px;
}
.__text-field.inner-icon .second {
  border-left-width: 1px;
  padding-left: 3rem;
  border-radius: var(--radius) var(--radius) var(--radius) var(--radius);
}

.__text-field.inner-icon .pre-section {
  display: flex;
  position: absolute;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  background-color: transparent;
  color:var(--pre-font-color);
  border-style: none;
  border-width: 0px;
  border-color: transparent;
  left:0px;
  top: 0px;
  bottom: 0px;
  padding-left: 0.75rem;
  align-items: center;
  box-sizing: border-box;

}

.__editor-text-box {
  width: 220px;
}
