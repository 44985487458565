#root {
  --main-bkg-color: #e3eafa;
  --main-font: inherit;
  --main-color: black;

  --menu-bkg-color: #443eac;
  --menu-font: inherit;
  --menu-color: white;

  --header-bkg-color: #e3eafa; /* #E3EAFA; #233c8b; #EFF9FF; */
  --header-font: inherit;
  --header-color: black;

  --title-bkg-color: white;

  transition: all 300ms;
  height: 100%;
}

#root > .app {
  display: grid;
  grid-template-columns: 256px 1fr;
  grid-template-rows: 64px 1fr;
  grid-template-areas:
    'menu header'
    'menu main';
  height: 100%;
}

#root > .app > header {
  grid-area: header;
}

#root > .app > main {
  background-color: var(--main-bkg-color); /* #E6ECFE;   #f5f7fd; */
  grid-area: main;
}

#root > .app > aside {
  grid-area: menu;
}

h1 {
  font-size: 1.6em;
}

/* @media (max-width: 900px) {
  #root {
    grid-template-columns: min(20vw, 90px) 1fr;
  }
} */

@media (max-width: 900px) {
  #root > .app {
    grid-template-columns: 0fr 1fr;
  }

  .__sidebar {
    display: none;
    /* transition: all 4s; */
    /* display:flex;
    width: 256px; */
  }
}

#sidebartoggler {
  display: none;
}

#sidebartoggler:checked ~ #navigation {
  display: flex;
  width: 256px;
}
