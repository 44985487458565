.__cv-uploader {
  --font-size: 0.875rem;
  --color: #6b7280;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: var(--font-size);
  color: var(--color);
  font-weight: 500;
  width: 100%;
}

.__cv-uploader> header > label {
  display: flex;
  justify-content: space-between;
  line-height: var(--line-height);
  font-size: var(--font-size);
  font-weight: 500;
  margin-left: 2px;

}

.__cv-uploader > main {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  width: 100%;
/*   max-width: 400px; */
  
}

.__cv-uploader>main>section {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
}
.__cv-uploader>main>section button{
  align-self: flex-end;
}

.__cv-uploader>main>section>embed {
  object-fit: cover;
  max-height: 400px;
  border: 1px solid #CCC;
  overflow: auto;
  border-radius: 15px;
  width: 100%;
  height: 300px;
}

.__cv-uploader>main>section input[type=file] {
  display: none;
}