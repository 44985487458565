.__steps {
  margin-bottom: 20px;
}

.__steps > .__card-container > nav > ol {
  display: flex;
  gap: 20px;
  border-color: rgb(209 213 219 / 1);
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  padding-inline: 20px;
  line-height: 1.25rem;
  font-size: 0.875rem;
  color: rgb(107 114 128 / 1); /* rgb(17 24 39 / 1); */
  font-weight: 500;
}

@media (max-width: 768px) {
  .__steps > .__card-container > nav > ol {
    flex-direction: column;
    padding-inline: 0px;
    gap: 0px;
  }

  .__steps > .__card-container > nav > ol > li {
    border-bottom-color: rgb(209 213 219 / 1);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 20px;
    line-height: 2rem;
  }

  .__steps > .__card-container > nav > ol > li > div {
    display: none;
  }
}

.__steps > .__card-container > nav > ol > li {
  display: flex;
  gap: 32px;
}
.__steps > .__card-container > nav > ol > li > a {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.__steps > .__card-container > nav > ol > li > a > span {
  display: flex;
  border-color: rgb(209 213 219 / 1);
  border-width: 2px;
  border-radius: 9999px;
  border-style: solid;

  height: 2.5rem;
  width: 2.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.__steps > .__card-container > nav > ol > li > a:hover {
  color: rgb(62, 61, 75);
}

.__steps > .__card-container > nav > ol > li > a > span.completed {
  background-color: rgb(79 70 229 / 1);
  border-color: rgb(79 70 229 / 1);
}

.__steps > .__card-container > nav > ol > li > a > span.current {
  border-color: rgb(79 70 229 / 1);
  color: rgb(79 70 229 / 1);
}

.__steps > .__card-container > nav > ol > li > a > span > svg {
  width: 1.5rem;
  height: 1.5rem;
  color: white;
}

.__steps > .__card-container > nav > ol > li > div {
  right: 0px;
  top: 0px;
  width: 1.25rem;
  height: 100%;
  /* background-color: red; */
}

.__steps > .__card-container > nav > ol > li > div > svg {
  display: block;
  width: 100%;
  height: 100%;
  color: rgb(209 213 219 / 1);
}
