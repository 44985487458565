.login-main {
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  background-color: #160d4b;
  background-size: 400% 400%;
  background-position: 0% 50%;
  animation: gradient 15s ease infinite;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.login-card {
  background-color: #150d4b;
  padding: 60px;
  width: 1024px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 60px;
  border-radius: 0.5rem/0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
