.__avatar {
  tab-size: 4;
  font-family: inherit;
  line-height: inherit;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  display: inline-block;
  position: relative;
}

.__avatar>img {
  tab-size: 4;
  font-family: inherit;
  line-height: inherit;
  box-sizing: border-box;
  border-width: 0;
  border-color: #e5e7eb;
  border-style: solid;
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
  border-radius: 9999px;
}

.__avatar.xs {
  height: 1.5rem;
  width: 1.5rem;
}

.__avatar.sm {
  height: 2rem;
  width: 2rem;
}

.__avatar.md {
  height: 2.5rem;
  width: 2.5rem;
}

.__avatar.lg {
  height: 3rem;
  width: 3rem;
}

.__avatar.xl {
  height: 3.5rem;
  width: 3.5rem;
}
